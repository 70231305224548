import app from 'common/client/app'
import pick from 'lodash/pick'

export function login (email, password) {
  return async dispatch => {
    let token = await app.authenticate({ strategy: 'local', email, password })
    let payload = await app.passport.verifyJWT(token.accessToken)
    let user = await app.service('user').get(payload.userId)
    dispatch({ type: 'USER_IS_LOGGED_IN', user })
    return user
  }
}

export function register (user) {
  return async dispatch => {
    // check that user name is unique
    await app.service('authManagement').create({
      action: 'checkUnique',
      value: user,
    })
    // create the user
    await app.service('user').create(user)
  }
}

export function sendResetPwd (email) {
  return async dispatch => {
    let response = await app.service('authManagement').create({
      action: 'sendResetPwd',
      value: { email },
    })
    return response
  }
}

export function resetPwdLong (token, password) {
  return async dispatch => {
    return app.service('authManagement').create({
      action: 'resetPwdLong',
      value: {
        token,
        password
      }
    })
  }
}

/**
 * @param {{ user: { email: string }, oldPassword: string, password: string }} value
 */
export function passwordChange (value) {
  return async dispatch => {
    return app.service('authManagement').create({
      action: 'passwordChange',
      value,
    })
  }
}

export function updateUser (user) {
  return async dispatch => {
    let id = user._id
    let values = pick(user, ['email', 'name', 'dob'])
    user = await app.service('user').patch(id, values)
    dispatch({ type: 'USER_IS_LOGGED_IN', user })
  }
}

/**
 * Store the `destination` parameter in the store, for future redirect after
 * a success login
 * @param {string} destination the URL to redirect to after successful login
 */
export function setDestination (destination) {
  return async dispatch => {
    dispatch({ type: 'SET_DESTINATION', destination })
  }
}

export function redirectTo (url) {
  return async dispatch => {
    await dispatch({ type: 'CLEAR_DESTINATION' })
    window.location = url
  }
}
