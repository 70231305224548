import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserForgotPasswordForm from '../forms/UserForgotPasswordForm'
import { sendResetPwd } from '../actions'
import { showSnack } from 'common/actions'

import Loading from '../components/Loading'

const mapDispatchToProps = {
  sendResetPwd,
  showSnack
}

class UserForgotPassword extends Component {
  state = {
    submitted: false,
    isLoading: false
  }

  submit = async (values) => {
    this.setState({ isLoading: true })

    try {
      let { email } = values

      let emailSent = await this.props.sendResetPwd(email)

      if (emailSent) {
        this.setState({ submitted: true, email: email, emailSent: emailSent, isLoading: false })
      }
    }
    catch (e) {
      console.log(e)
      this.props.showSnack(e.message)
      this.setState({ isLoading: false })
    }
  }

  render () {
    if (this.state.submitted) {
      return (
        <div className="success" >
          Thanks {this.state.emailSent.name.given}, we've sent an email to {this.state.email} with instructions to reset your password.
        </div>
      )
    }
    return (
      <div className="container">
        {
          this.state.isLoading
            ? <Loading inline={true}>Finding your account...</Loading>
            : <UserForgotPasswordForm onSubmit={this.submit} disabled={this.state.submitted} />
        }
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(UserForgotPassword)
