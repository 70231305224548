import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import UserChangePasswordForm from '../forms/UserChangePasswordForm'
import { passwordChange } from '../actions'
import { showSnack } from 'common/actions'

const mapStateToProps = ({ currentUser }) => ({ currentUser })
const mapDispatchToProps = {
  passwordChange,
  showSnack
}

class UserChangePassword extends Component {
  state = {
    isLoading: false
  }
  submit = async ({ oldPassword, password, confirmPassword }) => {
    this.setState({ isLoading: true })

    if (!password || password !== confirmPassword) {
      this.setState({ isLoading: false })
      throw new SubmissionError({ _error: 'Passwords do not match.' })
    }
    try {
      await this.props.passwordChange({
        user: {
          email: this.props.currentUser.email,
        },
        oldPassword,
        password,
      })

      this.setState({ isLoading: false })
      this.props.showSnack('Password changed successfully.')
    }
    catch (err) {
      this.setState({ isLoading: false })
      throw new SubmissionError({ _error: err.message })
    }
    // @todo something
  }

  render () {
    return (
      <div className="container">
        <UserChangePasswordForm isLoading={this.state.isLoading} onSubmit={this.submit} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword)
