import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const status = (state = { isLoading: true }, action) => {
  if (state.isLoading) {
    switch (action.type) {
      case 'USER_IS_LOGGED_IN':
      case 'USER_IS_ANONYMOUS': {
        state = { isLoading: false }
        return state
      }
      default: {
        return state
      }
    }
  }
  else {
    return state
  }
}

const currentUser = (state = null, action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN': {
      let { user } = action
      return user
    }
    case 'USER_IS_ANONYMOUS': {
      return null
    }
    default: {
      return state
    }
  }
}

const destination = (state = { }, action) => {
  switch (action.type) {
    case 'SET_DESTINATION': {
      return {
        url: action.destination
      }
    }
    case 'CLEAR_DESTINATION': {
      return { }
    }
    default: {
      return state
    }
  }
}

const snackbar = (state = {}, action) => {
  switch (action.type) {
    case 'NEW_MESSAGE':
      return { ...state, message: action.message, action: action.action, open: true }
    case 'DISMISS_MESSAGE':
      return { ...state, open: false }
    default:
      return state
  }
}

const reducers = combineReducers({
  status,
  currentUser,
  destination: persistReducer({ key: 'destination', storage }, destination),
  snackbar,
  routing: routerReducer,
  form: formReducer,
})

export default reducers
