import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'

import { TextField } from 'redux-form-material-ui'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const UserResetPasswordForm = ({ handleSubmit, theme, error }) => {
  return (
    <form onSubmit={ handleSubmit }>
      <Field
        name="password"
        label="Password"
        component={TextField}
        type="password"
        className="formGroup"
      />
      <Field
        name="confirmPassword"
        label="Confirm Password"
        component={TextField}
        type="password"
        className="formGroup"
      />

      { error && <div className="inlineError">{ error }</div> }

      <Button type="submit" variant="contained" className="button" style={theme.button.primary}>Reset Password</Button>

    </form>
  )
}

export default compose(withStyles(null, { withTheme: true }), reduxForm({ form: 'userResetPasswordForm' }))(UserResetPasswordForm)
