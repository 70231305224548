import React from 'react'
import ReactDOM from 'react-dom'
import reducers from './reducers'

// Redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'

// Middleware
import thunkMiddleware from 'redux-thunk'

// Components
import App from './components/App'

// Styles
import 'common/styles/index.scss'
import './index.scss'

// Theme
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from 'common/client/theme'

const muiTheme = createMuiTheme(theme)

let middleware = [ thunkMiddleware ]
let store = createStore(
  reducers,
  applyMiddleware(...middleware)
)
let persistor = persistStore(store)

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
)
