import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import UserResetPasswordForm from '../forms/UserResetPasswordForm'
import { SubmissionError } from 'redux-form'
import { resetPwdLong } from '../actions'
import { showSnack } from 'common/actions'

const mapStateToProps = ({ currentUser }) => ({ currentUser })
const mapDispatchToProps = { resetPwdLong, showSnack }

class UserResetPassword extends Component {
  state = {
    submitted: false
  }

  submit = async ({ password, confirmPassword }) => {
    if (password !== confirmPassword) {
      throw new SubmissionError({ _error: 'Passwords do not match.' })
    }

    try {
      let { match: { params } } = this.props
      await this.props.resetPwdLong(params.token, password)

      // @todo see https://www.npmjs.com/package/redux-flash-messages
      this.props.showSnack('Password reset successfully.')
      this.setState({ submitted: true })
    }
    catch (e) {
      let { message } = e
      throw new SubmissionError({ _error: message })
    }
  }

  render () {
    if (this.props.currentUser) {
      return (<Redirect to="/user/profile" />)
    }
    else if (this.state.submitted) {
      // @todo show a flash message
      return (<Redirect to="/user/login" />)
    }
    return (
      <div className="container">
        <UserResetPasswordForm onSubmit={this.submit} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserResetPassword)
