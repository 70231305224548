import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

class Loading extends React.Component {
  render () {
    let secondary = this.props.dark ? 'white' : this.props.theme.palette.primary.main

    return (
      <div className="loadingWrapper">
        <div className={classNames('loading', { 'inline': this.props.inline })}>
          <div className="circle" style={{ borderColor: this.props.theme.palette.secondary.main }} />
          <div className="circle secondary" style={{ borderColor: secondary }} />
        </div>
        <p style={{ textAlign: 'center' }}>{this.props.children}</p>
      </div>
    )
  }
}

export default withStyles(null, { withTheme: true })(Loading)
