import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect, Link } from 'react-router-dom'
import { login, redirectTo } from '../actions'
import { showSnack } from 'common/actions'

import UserLoginForm from '../forms/UserLoginForm'
import { SubmissionError } from 'redux-form'

import { withStyles } from '@material-ui/core/styles'

const mapStateToProps = ({ currentUser, destination }) => ({ currentUser, destination })
const mapDispatchToProps = { login, redirectTo, showSnack }

class UserLogin extends Component {
  state = {
    isLoading: false
  }

  submit = async ({ email, password }) => {
    this.setState({ isLoading: true })
    try {
      await this.props.login(email, password)
    }
    catch (err) {
      this.props.showSnack(err.message)
      this.setState({ isLoading: false })
      throw new SubmissionError({ _error: err.message })
    }
  }

  render () {
    const { theme, currentUser, destination } = this.props

    if (currentUser) {
      // Clear destination
      if (destination.url) {
        this.props.redirectTo(destination.url)
        return null
      }
      else {
        return (<Redirect to="/user/profile" />)
      }
    }
    return (
      <div className="container">
        <UserLoginForm isLoading={this.state.isLoading} onSubmit={ this.submit } />
        {
          !this.state.isLoading &&
            <footer>
              {/* <Link to="/user/register" style={{ color: theme.palette.primary.main }}>Register for AllCore360</Link> */}
              <Link to="/user/password" style={{ color: theme.palette.primary.main }}>Forgot Password?</Link>
            </footer>
        }
      </div>
    )
  }
}

export default compose(withStyles(null, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(UserLogin)
