import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'

import { dismissSnack } from 'common/actions'

const mapStateToProps = ({ snackbar }) => {
  return {
    message: snackbar.message,
    action: snackbar.action || 'Got it',
    open: snackbar.open
  }
}

const mapDispatchToProps = { dismissSnack }

class SnackBar extends React.Component {
  slideUp (props) {
    return <Slide {...props} direction="up" />
  }

  render () {
    if (!this.props.message) {
      return null
    }
    return (
      <Snackbar
        className="snackbar"
        open={this.props.open}
        autoHideDuration={4000}
        onClose={this.props.dismissSnack}
        TransitionComponent={this.slideUp}
        message={this.props.message && <span>{this.props.message}</span>}
        action={
          <Button style={{ color: this.props.theme.palette.secondary.main }}
            color="secondary" size="small"
            onClick={this.props.dismissSnack}>
            {this.props.action}
          </Button>
        }
      />
    )
  }
}

export default compose(withStyles(null, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SnackBar)
