import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Redirect } from 'react-router-dom'
import UserForm from '../forms/UserForm'
import UserChangePassword from './UserChangePassword'
import { updateUser } from '../actions'
import { logout, showSnack } from 'common/actions'

import { withStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'

const styles = {
  root: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 20,
    marginTop: -40
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser })
const mapDispatchToProps = { updateUser, logout, showSnack }

class UserProfile extends Component {
  state = {
    tabIndex: 0
  }
  submit = (values) => {
    this.props.updateUser(values)
    this.props.showSnack('Your profile was updated.')
  }

  handleChange = (event, index) => {
    this.setState({ tabIndex: index })
  }

  render () {
    const { classes } = this.props

    if (!this.props.currentUser) {
      return (<Redirect to="/user/login" />)
    }

    return (
      <div>
        <button className="buttonLink logout" onClick={this.props.logout}/>
        <Tabs className={classes.root} value={this.state.tabIndex} onChange={this.handleChange}>
          <Tab disableRipple={true} label="Your Profile" />
          <Tab disableRipple={true} label="Change Password" />
        </Tabs>

        <SwipeableViews
          animateHeight={false}
          index={this.state.tabIndex}
          onChangeIndex={(index) => {
            this.setState({ tabIndex: index })
          }}>

          <UserForm initialValues={this.props.currentUser} onSubmit={ this.submit } />
          <UserChangePassword />

        </SwipeableViews>
      </div>
    )
  }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(UserProfile)
